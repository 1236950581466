.hero {
  // padding-top: 46.57608695652174%;
  padding: 10rem 1rem 10rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;

  text-align: center;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  // & + .sprijin-competent {
  //   margin-top: -5rem;
  // }

  &__title {
    font-size: 1.5rem;
    grid-column: 1/2;
  }
  
  &__title,
  &__subtitle {
    grid-column: 1/2;
    margin: 1rem auto;
    padding: 1rem;
    display: inline-block;
    background-color: rgba($color: #fff, $alpha: 1);
    border-radius: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .hero {
    padding: 20rem 2.5rem 10rem;
    background-position: center top;
    background-size: cover;
  }
}
