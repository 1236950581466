@media (min-width: 768px) {
  .sprijin-competent {
    &:nth-child(odd) .image-content .image-content__background {
      grid-column: 2/3;
    }

  }

  .image-content-split {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  .article {
    &-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;

      &:nth-child(odd) {
        .article {
          &-image {
            grid-column: 1/2;
          }

          &-title,
          &-description {
            grid-column: 2/4;
          }
        }
      }
    }

    &-image {
      grid-column: 2/3;
      grid-row: 1/3;
    }
  }
}
