@media screen and (min-width: 768px) {
  .footer {
    &__logo {
      width: 100%;
    }
    .top-menu__logo {
      width: 100%;
      height: 0;
      padding-top: 20%;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: auto 1fr auto;
    }

    &__contact-info {
      // text-align: left;
    }

  }
}
