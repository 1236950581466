@media screen and (min-width: 1280px) {
  .pricing-table {
    grid-template-columns: repeat(3, 1fr);
  }  

  .kosten {
    &-content {
      padding: 1rem 3rem;
    }
  }
}
