.team {
  margin: 1rem auto;
  // max-width: 1280px;

  &__title {
    padding: 1rem;
    font-family: 'Libre Baskerville';
    font-size: 24px;
  }

  &__title--big {
    padding: 1rem;
    font-family: 'Libre Baskerville';
    font-size: 36px;
  }

  &__person {
    color: #4d3f38;
    background-color: #eceae6;
    text-align: center;
    font-family: 'Libre Baskerville';

    &-details {
      padding: 50px;
    }

    &-name {
      font-size: 24px;
      font-weight: bold;
    }

    &-role {
      color: #e0672f;
      font-style: italic;
    }

    &-description {
      padding-top: 25px;
      line-height: 30px;
    }
  }

  &__wrapper {
    // nimic
    display: grid;
    grid-gap: 1rem;
  }

  &__person-avatar {
    max-width: 100%;
    padding-top: 125%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.was-wir-tun .team__person-avatar {
  max-width: 100%;
  padding-top: 56.25%;
}
