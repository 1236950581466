@media screen and (min-width: 768px) {
  .team {
    &__wrapper {
      grid-template-columns: repeat(2, 1fr);
      // nimic
    }

    &__title {
      font-size: 32px;
    }
  }
}
