.ordered-list {
  display: grid;
  // max-width: 1280px;
  margin: 1rem auto;
  grid-gap: 2rem;

  &__image {
    padding-top: 80%;
    background-size: 100%;
    background-position: top center;
    background-repeat: no-repeat;
  }

  &__title {
    color: #352219;
    font-size: 30px;
    line-height: 1.2;
    font-family: "Libre Baskerville", serif;
    font-weight: bold;
    padding: 1rem;
  }
  
  &__item-title {
    font-size: 24px;
    font-weight: bold;
    font-family: "Libre Baskerville", serif;
    padding-left: 1rem;
    display: inline-block;
    width: calc(100% - 100px);
  }

  &__item-description {
    color: #89807c;
    font-size: 17px;
    margin: 1rem 0;
  }
}

.custom-counter {
  counter-set: custom-counter;
}

.count-me {
  counter-increment: custom-counter;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;

  // si mai bine
  align-items: center;

  &__label {
    width: 75%;
    padding-left: 1rem;
  }

  &::before {
    content: counter(custom-counter);
    width: 72px;
    height: 72px;
    background-color: #8dbcd2;
    font-size: 24px;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    // display: inline-block;
  }

  &:nth-child(even) {
    background-color: #f3f2f0;
  }
}
