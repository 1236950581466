.info-banner {
  background-color: #322c29;
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;
  color: #fff;
  font-size: 18px;
  margin: -6rem 2rem 5rem;
  grid-template-columns: 1fr;

  &__icon {
    grid-row: 1/3;
    font-size: 2.5rem;
    align-self: center;
    padding-right: 1.5rem;
  }

  &__title {
    font-size: 24px;
    font-family: "Libre Baskerville";
  }

  &__description {
    grid-row: 2/3;

    &::before {
      content: attr(data-left);
    }
    &::after {
      content: attr(data-right);
    }
  }
}

.calendly-badge-widget {
  display: inline-flex;
  margin: 0 auto 2.5rem;
  justify-content: center;
  align-items: center;
  border-style: none;
  border-radius: 5px;
  // width: calc(100% - 75px);
  // max-width: calc(100% - 140px);
  padding: 0 2rem;
  height: 72px;
  font-size: 24px;
  background: rgb(0, 162, 255);
  // text-transform: uppercase;
  color: rgb(255, 255, 255);
  box-shadow: rgb(0, 127, 201) 0px 3px 0px;
  transition: all 250ms ease 0s;
  position: relative;
  cursor: pointer;
  position: fixed;
  cursor: pointer;
  z-index: 1;
  bottom: 0;
  right: 60px;

  .calendly-badge-content {
    background-color: transparent;
  }

  .calendly-badge-content span {
    display: none;
  }

  &::after {
    content: "";
    background-image: url(#{$baseUrl}asset/image/arrow-right-down.png);
    position: absolute;
    width: 40px;
    height: 109px;
    right: -50px;
    bottom: 40px;
    background-size: contain;
  }
}

.content-center {
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .info-banner {
    padding: 4rem 7rem;
    grid-template-columns: auto 1fr auto 1fr;
  }
}

@media screen and (max-width: 768px) {
  .info-banner {
    & > div {
      grid-column: 1/-1;
      grid-row-start: initial;
      grid-row-end: initial;
    }
  }
}

#book-a-call {
  border: 5px solid rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  height: 60px;
  font-size: 18px;
  padding: 0 1.5rem;
  cursor: pointer;
  background-color: #0077cc;
  color: white;
  transition: 250ms;

  &:hover {
    color: #0077cc;
    background-color: white;
  }
}

.calendly-action {
  display: flex;
  justify-content: center;
}
