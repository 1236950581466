$icomoon-font-family: "bitbee" !default;
$icomoon-font-path: "asset/font" !default;

$bb-chevron-up: "\e900";
$bb-stethoscope: "\e902";
$bb-wheelchair_pickup: "\e903";
$bb-envelop: "\e904";
$bb-mail: "\e904";
$bb-email: "\e904";
$bb-contact: "\e904";
$bb-letter: "\e904";
$bb-phone-wave: "\e905";
$bb-telephone: "\e905";
$bb-contact1: "\e905";
$bb-support: "\e905";
$bb-call: "\e905";
$bb-location: "\e947";
$bb-calendar: "\e953";
$bb-bubbles4: "\e970";
$bb-instagram: "\e906";
$bb-facebook: "\e901";

