@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?jwn8lr') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?jwn8lr') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?jwn8lr##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="bb-"], [class*=" bb-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bb-chevron-up {
  &:before {
    content: $bb-chevron-up; 
  }
}
.bb-stethoscope {
  &:before {
    content: $bb-stethoscope; 
  }
}
.bb-wheelchair_pickup {
  &:before {
    content: $bb-wheelchair_pickup; 
  }
}
.bb-envelop {
  &:before {
    content: $bb-envelop; 
  }
}
.bb-mail {
  &:before {
    content: $bb-mail; 
  }
}
.bb-email {
  &:before {
    content: $bb-email; 
  }
}
.bb-contact {
  &:before {
    content: $bb-contact; 
  }
}
.bb-letter {
  &:before {
    content: $bb-letter; 
  }
}
.bb-phone-wave {
  &:before {
    content: $bb-phone-wave; 
  }
}
.bb-telephone {
  &:before {
    content: $bb-telephone; 
  }
}
.bb-contact1 {
  &:before {
    content: $bb-contact1; 
  }
}
.bb-support {
  &:before {
    content: $bb-support; 
  }
}
.bb-call {
  &:before {
    content: $bb-call; 
  }
}
.bb-location {
  &:before {
    content: $bb-location; 
  }
}
.bb-calendar {
  &:before {
    content: $bb-calendar; 
  }
}
.bb-bubbles4 {
  &:before {
    content: $bb-bubbles4; 
  }
}
.bb-instagram {
  &:before {
    content: $bb-instagram;     
    color: #e4405f;
  }
}
.bb-facebook {
  &:before {
    content: $bb-facebook;     
    color: #1877f2;
  }
}

