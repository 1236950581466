.footer {
  background-color: #322c29;
  padding: 4rem 1rem;

  &__social-media {
    display: flex;
    justify-content: center;
    align-self: center;
  }

  &__social-link {
    padding: 1rem;
    font-size: 2.5rem;
    text-decoration: none;
    background-color: #3e3836;
    border-radius: 50%;
    margin-left: 1rem;
    transition: 250ms;
  }

  &__social-link:hover {
    background-color: #e6b59e;
  }

  &__wrapper {
    // max-width: 1280px;
    margin: 0 auto;
  }

  &__logo {
    // width: 300px;
    // padding-top: 25%;
    justify-self: center;
    align-self: center;
  }

  &__contact-info {
    color: #fff;
    text-align: center;
  }

  &__contact-title {
    font-weight: bold;
    font-size: 2rem;
  }

  &__contact-description {
    font-weight: bold;
    font-size: 1.5rem;
  }
}
