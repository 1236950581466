@media (min-width: 1024px) {
  .top-menu {
    flex-direction: row;
    // margin: 0 auto;
    // width: 100%;
    bottom: initial;
    left: 1rem;
    top: 0;
    background-color: #fff;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top-width: 0;

    &__logo,
    &__link:visited,
    &__link:active,
    &__link {
      height: 80px;
      background-color: transparent;
      color: black;
      white-space: nowrap;
      padding: 0 1.5rem;
    }

    &__logo {
      width: 220px;
    }

    &__wrapper {
      display: flex;
    }
  }

  .content {
    padding-top: 100px;
  }

  label[for="top-menu__toggle"] {
    display: none;
  }
}
