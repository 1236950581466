@media screen and (min-width: 768px) {
  .ordered-list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;

    &__image {
      grid-column: 2/3;
      grid-row: 1/3;
    }

    &__title {
      padding: 1rem 2rem;
      font-size: 60px;
    }
    
    &__item-title {
      font-size: 24px;
      font-weight: bold;
      font-family: "Libre Baskerville", serif;
      padding-left: 1rem;
      display: inline-block;
      width: calc(100% - 100px);
    }
  
    &__item-description {
      color: #89807c;
      font-size: 17px;
      margin: 1rem 0;
    }  
  }
  .count-me {
    padding: 2rem 5rem;
  }  
}
