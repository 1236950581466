.kosten {
  background-color: #ead6ce;

  &__horizontal {
    padding-top: 25%;
    background-size: cover;
    margin-bottom: 1rem;
  }

  &__wrapper {
    // max-width: 1280px;
    margin: 1rem auto;
    padding-bottom: 1rem;
    padding: 1rem 2.5rem;
  }

  &__wrapper p:last-child {
    font-size: 20px;
  }

  .content__title {
    font-size: 2.5rem;
    padding: 1rem 0;
  }

  .content__description {
    color: #89807c;
    font-size: 17px;

    b {
      font-size: 19px;
      color: #000;
    }
  }

  &-content {
    padding: 1rem;
  }
}

.pricing-table {
  font-family: 'Libre Baskerville';
  // max-width: 1280px;
  margin: 1rem;
  display: grid;
  grid-gap: 2.5rem;

  &__content {
    padding: 1rem;
    text-align: center;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    z-index: -1;

    &::before {
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 16px;
    }

    &:first-child {
      border: 3px solid #d6d6d6;

      &::before {
        background-color: #d6d6d6;
      }
    }

    &:not(:first-child):not(:last-child) {
      border: 3px solid #0fa958;

      &::before {
        background-color: #0fa958;
      }
    }

    &:last-child {
      border: 3px solid #5551ff;

      &::before {
        background-color: #5551ff;
      }
    }
  }

  &__title {
    color: #676767;
    font-size: 60px;
  }

  &__sub-title {
    color: #676767;
    font-size: 36px;
  }

  &__list-wrapper {
    text-align: left;
  }

  &__list-item {
    text-align: left;
    display: flex;
    align-items: center;

    &::before {
      content: '✓';
      font-size: 32px;
      color: #0fa958;
      padding: 1rem;
    }
  }
}

.info-table {
  // max-width: 1280px;
  margin: 0 auto;

  &__data {
    padding: 1rem;
  }

  &__description {
    font-family: 'Libre Baskerville';
    font-size: 18px;
    text-align: center;
    color: #8dbcd2;
  }

  &__label,
  &__value {
    min-height: 60px;
    // line-height: 60px;
    padding: 0 2.5rem;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__value {
    text-align: right;
    font-weight: bold;
  }

  &__label:nth-child(4n + 1),
  &__value:nth-child(4n + 2) {
    background-color: #f3f2ed;
  }

  &__label:nth-child(4n),
  &__value:nth-child(4n + 1) {
    background-color: #ebeae5;
  }

  & i {
    color: #352219;
  }
}
