.sprijin-competent {
  margin: 1rem 0;

  &:nth-child(odd) .image-content .image-content__text {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  &:nth-child(odd) .image-content .image-content__background {
    grid-column-start: 1;
  }

}

.image-content__text {
  .team__title {
    margin-left: -14px;
    margin-bottom: 0;
  }
}

.nu-stiu {
  padding-top: 62.25%;
  width: 100%;
  background-size: cover;
  background-position: center center;
}
.article-image {
  min-height: 200px;
  background-size: cover;
  background-position: center center;
}

.article-wrapper {
  padding: 1rem 0;
}

.image-content {
  display: grid;
  min-height: 200px;

  &__background {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 50vh;
  }
  
  &__text {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #f3f2f0;
  }
}
