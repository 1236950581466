@media screen and (min-width: 768px) {
  .kosten {
    &-content {
      padding: 1rem 2rem;
    }

    .content__title {
      padding-bottom: 0;
    }
  }

  .pricing-table {
    grid-template-columns: repeat(2, 1fr);
    margin: 1rem;
  }

  .info-table {
    &__data {
      display: grid;
      grid-template-columns: 1fr auto;
    }
  }
}
